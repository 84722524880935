import React, { useRef } from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout';
import { BlogPostHeader, TextSlice, ShareButtons } from 'components/Blog';
import styled from 'styled-components';
import { Row, Col, Container as Grid } from 'styled-bootstrap-grid';
import { media } from 'styled-bootstrap-grid';

const Container = styled.section`
  position: relative;
  padding: 68px 0;

  h2 {
    font-size: 22px;

    ${media.lg`
      font-size: 32px;
    `}
  }

  p,
  li {
    font-size: 16px;
    line-height: 1.8;
    font-weight: 400;
  }
`;

const StyledShare = styled(ShareButtons)``;

const StyledCol = styled(Col)`
  position: relative;
`;

const PressPost = ({ data, location, ...props }) => {
  const containerRef = useRef();
  const {
    data: {
      meta_title,
      meta_image,
      meta_description,
      title,
      subtitle,
      featured_image,
      text,
      release_date,
    },
    lang: pageLang,
    alternate_languages: altLangs,
  } = data.post.edges[0].node;

  return (
    <Layout
      pageLang={pageLang}
      altLangs={altLangs}
      location={location}
      seo={{
        title: meta_title || title.text,
        description: meta_description || subtitle.text,
        image: meta_image?.url || featured_image.url,
      }}
      whiteHeader={true}
    >
      <BlogPostHeader
        publishedAt={release_date}
        title={title}
        subtitle={subtitle}
        image={featured_image}
        pageLang={pageLang}
      />
      <Container ref={containerRef}>
        <Grid>
          <Row>
            <StyledCol col={12} lg={8} lgOffset={2}>
              <StyledShare bodyRef={containerRef} />
              <TextSlice data={{ text }} />
            </StyledCol>
          </Row>
        </Grid>
      </Container>
    </Layout>
  );
};

export const pageQuery = graphql`
  query PressPostQuery($id: String!) {
    post: allPrismicPressPost(filter: { id: { eq: $id } }) {
      edges {
        node {
          lang
          alternate_languages {
            lang
            uid
          }
          data {
            meta_title
            meta_description
            meta_image {
              url
            }
            title {
              text
            }
            subtitle {
              text
            }
            featured_image {
              url
            }
            company_logo {
              url
            }
            text {
              html
            }
            release_date
            post_type # false = Press release, true = External news
            read_more_link {
              url
              target
            }
            video_link {
              url
            }
            press_release_document {
              url
            }
          }
          uid
          first_publication_date
        }
      }
    }
  }
`;

export default PressPost;
